import type { LayoutLoad } from './$types';
import { auth, session } from '$stores/auth';
import { master } from '$stores/master';
import { get } from 'svelte/store';
import { PUBLIC_BUILD_NAME } from '$env/static/public';
import { themeStore } from '$stores/theme';

// root의 layout load 는 페이지 이동시 항상 실행이 됨
export const load: LayoutLoad = async ({ url, parent }) => {
    await parent();
    const { pathname } = url;
    const isDemo = PUBLIC_BUILD_NAME === 'demo_allibee';
    const isDev = __IS_DEV_SERVER_ENV__;

    return {
        pathname,
        isDemo,
        isDev,
        master: get(master),
        auth: get(auth),
        session: get(session),
        title: 'allibee',
        themeConfig: get(themeStore),
    };
};

export const prerender = 'auto';
export const ssr = true;
// export const trailingSlash = 'always';
export const trailingSlash = 'ignore';
